
import { Component, Vue, Watch } from 'vue-property-decorator';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { LogAction } from '@/types/logAction';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import RepositoryFactory from '@/api/RepositoryFactory';
import logRepository from '@/api/logRepository';
import { TabIds } from '@/views/Home.vue';
import { useSysInfoStore } from '@/storeV2/sysInfo';
import { ProjectKey } from '@/types/projectKey';

const lastTimeRepository = RepositoryFactory.lastTime;

@Component({
  components: {
    VueCtkDateTimePicker,
  },
})
export default class GraphSetting extends Vue {
  dataPickerValue = this['$store'].getters.targetTime;

  enDataPickerValue = this['$store'].getters.enTargetTime;

  private displayDam = true;

  private displaySuii = true;

  private intervalToChangeTime = '10minute';

  private enIntervalToChangeTime = '1hour';

  // DateTimePickerが勝手に更新する不具合用フラグ
  private bugFixFlag = false;

  get loading() {
    return this['$store'].getters.loading;
  }

  get tabId(): TabIds {
    return this['$store'].getters.tabId;
  }

  get helpTeam() {
    return this['$store'].getters.helpTeam;
  }

  get isTonegawaApp() {
    const sysInfoStore = useSysInfoStore();
    if (sysInfoStore.projectKey === ProjectKey.ToneFlood || sysInfoStore.projectKey === ProjectKey.ToneLowFlow) {
      return true;
    }
    return false;
  }

  get stations() {
    localStorage.setItem('filteredStations', JSON.stringify([]));
    const { stationsMap, basinId } = this['$store'].getters;
    if (!Object.keys(stationsMap).length) return [];
    if (!basinId) return [];

    const stations = this['$store'].getters.currentStationIds.filter((station) => {
      const stationData = stationsMap[station];
      // filter
      if (!this.displayDam && stationData.type === 7) return false;
      if (!this.displaySuii && stationData.type === 4) return false;
      if (this.managementClasses.length > 0 && this.selectedClass !== '全部' && stationData.class !== this.selectedClass) return false;
      return true;
    }).map((station) => {
      const stationData = stationsMap[station];
      let { name } = stationData;

      // アンサンブル専用
      if (this.tabId === 'ensembleCalculation') {
        name = name === '松川ダム(ゲート放流)' ? '松川ダム' : name;
      }
      return {
        id: station,
        name,
        type: stationData.type,
        class: stationData.class,
      };
    });
    localStorage.setItem('filteredStations', JSON.stringify(stations.map((station) => station.id)));
    return stations;
  }

  get type() {
    return this['$store'].getters.type;
  }

  get stationId() {
    return this['$store'].getters.stationId;
  }

  set stationId(value) {
    this['$store'].commit('setStationId', value);
    const { scales, type } = this['$store'].getters.stationsMap[value];
    this['$store'].commit('setMaxHScales', scales.maxHList);
    this['$store'].commit('setMinHScales', scales.minHList);
    this['$store'].commit('setMaxQScales', scales.maxQList);
    this['$store'].commit('setType', type);
  }

  get basinId() {
    return this['$store'].getters.basinId;
  }

  // kouu
  get kouuScale() {
    return this['$store'].getters.kouuScale;
  }

  set kouuScale(value) {
    if (this.tabId === 'multiStations') {
      localStorage.setItem('compare-water-KouuScale', value);
    } else {
      localStorage.setItem(`${this.basinId}-${this.stationId}-KouuScale`, value);
    }
    this['$store'].commit('setKouuScale', value);
    this.rerenderFunction();
  }

  get kouuScales() {
    return ['自動', ...this['$store'].getters.kouuScales];
  }

  // ruika
  get ruikaScale() {
    return this['$store'].getters.ruikaScale;
  }

  set ruikaScale(value) {
    if (this.tabId === 'multiStations') {
      localStorage.setItem('compare-water-RuikaScale', value);
    } else {
      localStorage.setItem(`${this.basinId}-${this.stationId}-RuikaScale`, value);
    }
    this['$store'].commit('setRuikaScale', value);
    this.rerenderFunction();
  }

  get ruikaScales() {
    return ['自動', ...this['$store'].getters.ruikaScales];
  }

  // minHScale
  get minHScale() {
    return this['$store'].getters.minHScale;
  }

  set minHScale(value) {
    if (this.tabId === 'multiStations') {
      localStorage.setItem('compare-water-MinHScale', value);
    } else {
      localStorage.setItem(`${this.basinId}-${this.stationId}-MinHScale`, value);
    }
    this['$store'].commit('setMinHScale', value);
    if (value === '自動') {
      if (this.tabId === 'multiStations') {
        localStorage.setItem('compare-water-MaxHScale', value);
      } else {
        localStorage.setItem(`${this.basinId}-${this.stationId}-MaxHScale`, value);
      }
      this['$store'].commit('setMaxHScale', value);
    }
    this.rerenderFunction();
  }

  get minHScales() {
    const scales = [...this['$store'].getters.minHScales];
    scales.sort((a, b) => a - b).reverse();
    return ['自動', ...scales];
  }

  // maxHScale
  get maxHScale() {
    return this['$store'].getters.maxHScale;
  }

  set maxHScale(value) {
    if (this.tabId === 'multiStations') {
      localStorage.setItem('compare-water-MaxHScale', value);
    } else {
      localStorage.setItem(`${this.basinId}-${this.stationId}-MaxHScale`, value);
    }
    this['$store'].commit('setMaxHScale', value);
    if (value === '自動') {
      if (this.tabId === 'multiStations') {
        localStorage.setItem('compare-water-MinHScale', value);
      } else {
        localStorage.setItem(`${this.basinId}-${this.stationId}-MinHScale`, value);
      }
      this['$store'].commit('setMinHScale', value);
    }
    this.rerenderFunction();
  }

  get maxHScales() {
    const scales = [...this['$store'].getters.maxHScales];
    scales.sort((a, b) => a - b).reverse();
    return ['自動', ...scales];
  }

  // maxQScale
  get maxQScale() {
    return this['$store'].getters.maxQScale;
  }

  set maxQScale(value) {
    if (this.tabId === 'multiStations') {
      localStorage.setItem('compare-water-MaxQScale', value);
    } else {
      localStorage.setItem(`${this.basinId}-${this.stationId}-MaxQScale`, value);
    }
    this['$store'].commit('setMaxQScale', value);
    this.rerenderFunction();
  }

  get maxQScales() {
    return ['自動', ...this['$store'].getters.maxQScales];
  }

  get yskSpan() {
    return this['$store'].getters.yskSpan;
  }

  set yskSpan(value) {
    this['$store'].commit('setYskSpan', value);
  }

  get enYskSpan() {
    return this['$store'].getters.enYskSpan;
  }

  set enYskSpan(value) {
    this['$store'].commit('setEnYskSpan', value);
  }

  get latestTargetTime() {
    return this['$store'].getters.latestTargetTime ?? '2200-01-01 00:00';
  }

  get enLatestTargetTime() {
    return this['$store'].getters.enLatestTargetTime;
  }

  get targetTime() {
    return this['$store'].getters.targetTime;
  }

  set targetTime(value) {
    // VueCtkDateTimePickerが勝手に分以降を変更しようとするのを回避
    if (this.bugFixFlag && !this.loading) {
      this['$store'].commit('setTargetTime', value);
    }
  }

  get enTargetTime() {
    return this['$store'].getters.enTargetTime;
  }

  set enTargetTime(value) {
    // VueCtkDateTimePickerが勝手に分以降を変更しようとするのを回避
    if (this.bugFixFlag && !this.loading) {
      this['$store'].commit('setEnTargetTime', value);
    }
  }

  get autoLoad() {
    return this['$store'].getters.autoLoad;
  }

  set autoLoad(value) {
    this['$store'].commit('setAutoLoad', value);
  }

  private ensampleDisplayMemberItems =
    [
      { text: '全メンバー', value: 'all' },
      { text: '上位中位下位', value: 'topMidBot' },
      { text: '上位5メンバー', value: 'top' },
      { text: '下位10メンバー', value: 'bot' },
    ]

  get ensampleDisplayMember() {
    return this['$store'].getters.ensampleDisplayMember;
  }

  set ensampleDisplayMember(value) {
    this['$store'].commit('setEnsampleDisplayMember', value);
  }

  get subResultSwitchName() {
    return this['$store'].getters.currentContent?.subResult?.switchName ?? '';
  }

  get isJizenHouryu() {
    return this['$store'].getters.isJizenHouryu;
  }

  set isJizenHouryu(value) {
    if (this.showSubResult) {
      this['$store'].commit('setIsJizenHouryu', value);
      if (value === true) {
        this['$store'].commit('setBasinId', this['$store'].getters.currentContent.resultKey);
      } else {
        this['$store'].commit('setBasinId', this['$store'].getters.currentContent?.subResult?.resultKey);
      }
    }
  }

  get showSubResult() {
    return this['$store'].getters.currentContent?.subResult?.enable;
  }

  get defaultManualUrl() {
    const bucketName = document.domain.includes('stage.damsys.riskma.net') ? 'ctie.damsys.calc.stage' : 'ctie.damsys.calc';
    return `https://s3.ap-northeast-1.amazonaws.com/${bucketName}/manuals/common/damjizen_manual.pdf`;
  }

  get hasManual() {
    return this['$store'].getters.currentContent?.subResult?.hasManual;
  }

  get manualName() {
    return this['$store'].getters.currentContent?.subResult?.manualName ?? '';
  }

  get manualUrl() {
    const bucketName = document.domain.includes('stage.damsys.riskma.net') ? 'ctie.damsys.calc.stage' : 'ctie.damsys.calc';
    return `https://s3.ap-northeast-1.amazonaws.com/${bucketName}/${this['$store'].getters.currentContent?.subResult?.manualUrl}`;
  }

  get selectedClass() {
    return this['$store'].getters.selectedClass;
  }

  set selectedClass(value) {
    this['$store'].commit('setSelectedClass', value);
  }

  get managementClasses() {
    const l: string[] = this['$store'].getters.currentContent?.classes ?? [];
    return ['全部', ...l];
  }

  async changeObservatory() {
    console.log('changeObservatory', this.stations.find((station) => station.id === this.stationId)?.name);
    await logRepository.log({
      action: LogAction.ChangeObservatory,
      params: {
        stationId: this.stationId,
        stationName: this.stations.find((station) => station.id === this.stationId)?.name,
      },
    });
  }

  /**
   * 絞り込み時に設定中のstationIdが絞り込み条件外のものになった場合に
   * 絞り込み条件の最初のstationIdを設定する
   */
  @Watch('stations')
  setDefaultStationId() {
    let f = false;
    this.stations.forEach((station) => {
      if (station.id === this.stationId) {
        f = true;
      }
    });
    if (!f && this.stations[0]) {
      const [{ id }] = this.stations;
      this.stationId = id;
    }
  }

  rerenderFunction() {
    this['$store'].commit('setRerender', true);
    this.$nextTick(() => {
      this['$store'].commit('setRerender', false);
    });
  }

  clickLeftBtn() {
    let date;
    let time;
    if (this.tabId === 'ensembleCalculation') {
      date = new Date(this.enTargetTime);
      time = this.enIntervalToChangeTime;
    } else {
      date = new Date(this.targetTime);
      time = this.intervalToChangeTime;
    }

    if (time === '10minute') {
      date.setMinutes(date.getMinutes() - 10);
    } else if (time === '30minute') {
      date.setMinutes(date.getMinutes() - 30);
    } else if (time === '1hour') {
      date.setHours(date.getHours() - 1);
    } else if (time === '3hour') {
      date.setHours(date.getHours() - 3);
    } else if (time === '6hour') {
      date.setHours(date.getHours() - 6);
    } else if (time === '12hour') {
      date.setHours(date.getHours() - 12);
    } else if (time === '1day') {
      date.setDate(date.getDate() - 1);
    } else if (time === '3day') {
      date.setDate(date.getDate() - 3);
    }

    if (this.tabId === 'ensembleCalculation') {
      this.enTargetTime = `${date.getFullYear()}-${(`0${date.getMonth() + 1}`).slice(-2)}-${(`0${date.getDate()}`).slice(-2)} ${(`0${date.getHours()}`).slice(-2)}:${(`0${date.getMinutes()}`).slice(-2)}`;
    } else {
      this.targetTime = `${date.getFullYear()}-${(`0${date.getMonth() + 1}`).slice(-2)}-${(`0${date.getDate()}`).slice(-2)} ${(`0${date.getHours()}`).slice(-2)}:${(`0${date.getMinutes()}`).slice(-2)}`;
    }
  }

  clickRightBtn() {
    let date;
    let time;
    if (this.tabId === 'ensembleCalculation') {
      date = new Date(this.enTargetTime);
      time = this.enIntervalToChangeTime;
    } else {
      date = new Date(this.targetTime);
      time = this.intervalToChangeTime;
    }

    if (time === '10minute') {
      date.setMinutes(date.getMinutes() + 10);
    } else if (time === '30minute') {
      date.setMinutes(date.getMinutes() + 30);
    } else if (time === '1hour') {
      date.setHours(date.getHours() + 1);
    } else if (time === '3hour') {
      date.setHours(date.getHours() + 3);
    } else if (time === '6hour') {
      date.setHours(date.getHours() + 6);
    } else if (time === '12hour') {
      date.setHours(date.getHours() + 12);
    } else if (time === '1day') {
      date.setDate(date.getDate() + 1);
    } else if (time === '3day') {
      date.setDate(date.getDate() + 3);
    }

    if (this.tabId === 'ensembleCalculation') {
      this.enTargetTime = `${date.getFullYear()}-${(`0${date.getMonth() + 1}`).slice(-2)}-${(`0${date.getDate()}`).slice(-2)} ${(`0${date.getHours()}`).slice(-2)}:${(`0${date.getMinutes()}`).slice(-2)}`;
    } else {
      this.targetTime = `${date.getFullYear()}-${(`0${date.getMonth() + 1}`).slice(-2)}-${(`0${date.getDate()}`).slice(-2)} ${(`0${date.getHours()}`).slice(-2)}:${(`0${date.getMinutes()}`).slice(-2)}`;
    }
  }

  get disableRightBtn() {
    let date;
    let time;
    if (this.tabId === 'ensembleCalculation') {
      date = new Date(this.enTargetTime);
      time = this.enIntervalToChangeTime;
    } else {
      date = new Date(this.targetTime);
      time = this.intervalToChangeTime;
    }

    if (time === '10minute') {
      date.setMinutes(date.getMinutes() + 10);
    } else if (time === '30minute') {
      date.setMinutes(date.getMinutes() + 30);
    } else if (time === '1hour') {
      date.setHours(date.getHours() + 1);
    } else if (time === '3hour') {
      date.setHours(date.getHours() + 3);
    } else if (time === '6hour') {
      date.setHours(date.getHours() + 6);
    } else if (time === '12hour') {
      date.setHours(date.getHours() + 12);
    } else if (time === '1day') {
      date.setDate(date.getDate() + 1);
    } else if (time === '3day') {
      date.setDate(date.getDate() + 3);
    }

    if (this.tabId === 'ensembleCalculation') {
      return new Date(this.enLatestTargetTime) < date;
    }
    return new Date(this.latestTargetTime) < date;
  }

  clickNewBtn() {
    this.updateTargetTimeToNow();
  }

  async updateTargetTimeToNow() {
    if (this.tabId === 'ensembleCalculation') {
      const { data: lastTime } = await lastTimeRepository.get({
        pathParams: {
          basinId: this.basinId,
          stationId: this.stationId,
        },
        queryParams: {
          isEn: true,
        },
      });
      this['$store'].commit('setEnLatestTargetTime', lastTime.lastTime);
      this.enTargetTime = lastTime.lastTime;
    } else {
      const { data: lastTime } = await lastTimeRepository.get({
        pathParams: {
          basinId: this.basinId,
          stationId: this.stationId,
        },
        queryParams: {
          isEn: false,
        },
      });
      this['$store'].commit('setLatestTargetTime', lastTime.lastTime);
      this.targetTime = lastTime.lastTime;
    }
  }

  clickCheckBox() {
    let f = false;
    this.stations.forEach((station) => {
      if (station.id === this.stationId) {
        f = true;
      }
    });
    if (!f && this.stations[0]) {
      const [{ id }] = this.stations;
      this.stationId = id;
    }
  }

  created() {
    this.bugFixFlag = false;
    setTimeout(() => {
      this.bugFixFlag = true;
      this.dataPickerSetup();
    }, 1000);
  }

  // detapickerのチェックボタンとnowボタン
  dataPickerSetup() {
    const addEvent = (event) => {
      const elementValidate = document.getElementsByClassName('datepicker-button validate');
      for (let i = 0; i < elementValidate.length; i += 1) {
        elementValidate[i].removeEventListener('click', event);
        elementValidate[i].addEventListener('click', event);
      }
      const elementNow = document.getElementsByClassName('datepicker-button now');
      for (let i = 0; i < elementNow.length; i += 1) {
        elementNow[i].removeEventListener('click', event);
        elementNow[i].addEventListener('click', event);
      }
    };

    this.dataPickerValue = this.targetTime;
    this.enDataPickerValue = this.enTargetTime;

    if (this.tabId === 'autoCalculation' || this.tabId === 'manualCalculation' || this.tabId === 'multiStations' || this.tabId === 'statusChart') {
      addEvent(this.reflectTargetTime);
    } if (this.tabId === 'ensembleCalculation') {
      addEvent(this.reflectEnTargetTime);
    }

    const datepickerElement = document.getElementsByClassName('datepicker flex flex-direction-column').item(0) as HTMLElement;
    if (datepickerElement) {
      datepickerElement.style.height = '500px';
    }
  }

  reflectTargetTime() {
    this.targetTime = this.dataPickerValue;
    this.autoLoad = false;
  }

  reflectEnTargetTime() {
    this.enTargetTime = this.enDataPickerValue;
    this.autoLoad = false;
  }

  @Watch('autoLoad')
  autoLoadWatcher() {
    if (this.autoLoad) {
      this.updateTargetTimeToNow();
    }
  }

  @Watch('targetTime')
  targetTimeWatcher() {
    if (this.dataPickerValue !== this.targetTime) {
      this.dataPickerValue = this.targetTime;
    }
  }

  @Watch('enTargetTime')
  enTargetTimeWatcher() {
    if (this.enDataPickerValue !== this.enTargetTime) {
      this.enDataPickerValue = this.enTargetTime;
    }
  }

  @Watch('tabId')
  changeTab() {
    this.bugFixFlag = false;
    setTimeout(() => {
      this.bugFixFlag = true;
    }, 300);

    this.$nextTick(() => {
      this['$store'].commit('setRerender', true);
      this.$nextTick(() => {
        this['$store'].commit('setRerender', false);
      });
    });
    if (this.tabId === 'ensembleCalculation') {
      if (!['11okususobana', '12susobana', '16matsukawa'].includes(this.stationId)) {
        this.stationId = '11okususobana';
      }
    }
  }

  private yskSpanScales = [
    { value: 6, text: '6時間先' },
    { value: 12, text: '12時間先' },
    { value: 24, text: '24時間先' },
    { value: 48, text: '48時間先' },
    { value: 72, text: '72時間先' },
    { value: 96, text: '96時間先' },
  ];

  private enYskSpanScales = [
    { value: 24, text: '1日先' },
    { value: 24 * 3, text: '3日先' },
    { value: 24 * 7, text: '7日先' },
    { value: 24 * 11, text: '11日先' },
  ];

  private station = null;

  private test = 1;
}
